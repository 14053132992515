import React, { useCallback, useRef, useState, useEffect } from "react";

import TUICalendar from "@toast-ui/react-calendar";

import "./App.css";
import "./assets/css/tui-calendar.css";
import "./assets/css/powerplate.co.uk.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";

import moment from "moment";

import { ReactComponent as NextLogo } from "./assets/svg/next.svg";
import { ReactComponent as PrevLogo } from "./assets/svg/prev.svg";

var COMMON_CUSTOM_THEME = {
  "common.holiday.color": "#d20824",
  /*  CD (EV on 20210324): SUNDAY COLOR */
  "common.dayname.color": "#333",
  /*  CD (EV on 20210324): dayname color must be same with border calendar */
};

const start = new Date();
// const end = new Date(new Date().setMinutes(start.getMinutes() + 30));
// always use the active timezone for areas that use daylight savings time

console.log(new Date("2021-03-25 11:30:49"));
const schedules = [
  {
    calendarId: "1",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "1",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-49976790927" href="https://www.eventbrite.co.uk/e/49976790927" target="_blank">Register</a>',
    attendees: [],
    start: "2021-03-25T10:00:00-05:00",
    end: "2021-03-25T12:00:00-05:00",
  },
  {
    calendarId: "2",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "2",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-146801036853"  href="https://www.eventbrite.co.uk/e/146801036853" target="_blank">Register</a>',
    start: "2021-03-31T14:00:00+01:00",
    end: "2021-03-31T16:00:00+01:00",
  },
  {
    calendarId: "3",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "3",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-147583585477" href="https://www.eventbrite.co.uk/e/147583585477" target="_blank">Register</a>',
    start: "2021-04-29T14:00:00+01:00",
    end: "2021-04-29T16:00:00+01:00",
  },
  {
    calendarId: "4",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "4",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-148054766793" href="https://www.eventbrite.co.uk/e/148054766793" target="_blank">Register</a>',
    start: "2021-04-15T10:00:00-05:00",
    end: "2021-04-15T12:00:00-05:00",
  },
  {
    calendarId: "5",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "5",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-155700124263" href="https://www.eventbrite.co.uk/e/155700124263" target="_blank">Register</a>',
    start: "2021-06-12T11:00:00-05:00",
    end: "2021-06-12T13:00:00-05:00",
  },
  {
    calendarId: "6",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "6",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-155989969197" href="https://www.eventbrite.co.uk/e/155989969197" target="_blank">Register</a>',
    start: "2021-05-26T13:00:00-05:00",
    end: "2021-05-26T15:00:00-05:00",
  },
  {
    calendarId: "7",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "7",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-158646418709" href="https://www.eventbrite.co.uk/e/158646418709" target="_blank">Register</a>',
    start: "2021-06-21T12:00:00-05:00",
    end: "2021-06-21T14:00:00-05:00",
  },
  {
    calendarId: "8",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "8",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-158738197221" href="https://www.eventbrite.co.uk/e/158738197221" target="_blank">Register</a>',
    start: "2021-07-07T08:00:00-05:00",
    end: "2021-07-07T10:00:00-05:00",
  },
  {
    calendarId: "9",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "9",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-191125019077" href="https://www.eventbrite.co.uk/e/191125019077" target="_blank">Register</a>',
    start: "2021-10-22T09:00:00-05:00",
    end: "2021-10-22T11:00:00-05:00",
  },
  {
    calendarId: "10",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "11",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-196508400917" href="https://www.eventbrite.co.uk/e/196508400917" target="_blank">Register</a>',
    start: "2021-11-29T12:00:00-06:00",
    end: "2021-11-29T14:00:00-06:00",
  },
  {
    calendarId: "11",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "12",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-196495482277" href="https://www.eventbrite.co.uk/e/196495482277" target="_blank">Register</a>',
    start: "2021-12-15T09:00:00-06:00",
    end: "2021-12-15T11:00:00-06:00",
  },
  {
    calendarId: "12",
    category: "time",
    isVisible: true,
    title: "Virtual Discover Workshop",
    id: "13",
    state: "",
    location: "Online event",
    body:
      '<a id="eventbrite-widget-modal-trigger-196513566367" href="https://www.eventbrite.co.uk/e/196513566367" target="_blank">Register</a>',
    start: "2021-12-18T10:00:00-06:00",
    end: "2021-12-18T12:00:00-06:00",
  },
];

const calendars = [
  {
    id: "1",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "2",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "3",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "4",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "5",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "6",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "7",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "8",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "9",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "10",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "11",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
  {
    id: "12",
    name: "Power Plate",
    color: "#ffffff",
    bgColor: "#d20824",
    dragBgColor: "#d20824",
    borderColor: "#d20824",
  },
];

function App() {
  const cal = useRef(null);

  const [dateRange, setdateRange] = useState(null);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // var exampleCallback = function () {
  //   console.log("Order complete!");
  // };

  // window.EBWidgets.createWidget({
  //   widgetType: "checkout",
  //   eventId: "49976790927",
  //   modal: true,
  //   modalTriggerElementId: "eventbrite-widget-modal-trigger-49976790927",
  //   onOrderComplete: exampleCallback,
  // });

  // window.EBWidgets.createWidget({
  //   widgetType: "checkout",
  //   eventId: "146801036853",
  //   modal: true,
  //   modalTriggerElementId: "eventbrite-widget-modal-trigger-146801036853",
  //   onOrderComplete: exampleCallback,
  // });

  // window.EBWidgets.createWidget({
  //   widgetType: "checkout",
  //   eventId: "147583585477",
  //   modal: true,
  //   modalTriggerElementId: "eventbrite-widget-modal-trigger-147583585477",
  //   onOrderComplete: exampleCallback,
  // });

  const onClickSchedule = useCallback((e) => {
    // const { calendarId, id } = e.schedule;
    // // const el = cal.current.calendarInst.getElement(id, calendarId);

    console.log("onClickSchedule");
  }, []);

  const onBeforeCreateSchedule = useCallback((scheduleData) => {
    console.log("onBeforeCreateSchedule");
    const schedule = {
      id: String(Math.random()),
      title: scheduleData.title,
      isAllDay: scheduleData.isAllDay,
      start: scheduleData.start,
      end: scheduleData.end,
      category: scheduleData.isAllDay ? "allday" : "time",
      dueDateClass: "",
      location: scheduleData.location,
      raw: {
        class: scheduleData.raw["class"],
      },
      state: scheduleData.state,
    };

    cal.current.calendarInst.createSchedules([schedule]);
  }, []);

  const onBeforeDeleteSchedule = useCallback((res) => {
    const { id, calendarId } = res.schedule;

    cal.current.calendarInst.deleteSchedule(id, calendarId);
    console.log("onBeforeDeleteSchedule");
  }, []);

  const onBeforeUpdateSchedule = useCallback((e) => {
    const { schedule, changes } = e;

    cal.current.calendarInst.updateSchedule(
      schedule.id,
      schedule.calendarId,
      changes
    );
    console.log("onBeforeUpdateSchedule");
  }, []);

  function _getFormattedTime(time) {
    function leftPad(number, targetLength) {
      var output = number + "";
      while (output.length < targetLength) {
        output = "0" + output;
      }
      return output;
    }

    const date = new Date(time);
    const h = date.getHours();
    const m = date.getMinutes();

    return `${h}:${leftPad(m, 2)}`;
  }

  function _getTimeTemplate(schedule, isAllDay) {
    var html = [];

    if (!isAllDay) {
      html.push("<strong>" + _getFormattedTime(schedule.start) + "</strong> ");
    }
    if (schedule.isPrivate) {
      html.push('<span class="calendar-font-icon ic-lock-b"></span>');
      html.push(" Private");
    } else {
      if (schedule.isReadOnly) {
        html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
      } else if (schedule.recurrenceRule) {
        html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
      } else if (schedule.attendees.length) {
        html.push('<span class="calendar-font-icon ic-user-b"></span>');
      } else if (schedule.location) {
        html.push('<span class="calendar-font-icon ic-location-b"></span>');
      }
      html.push(" " + schedule.title);
    }

    return html.join("");
  }

  const templates = {
    time: function (schedule) {
      return _getTimeTemplate(schedule, false);
    },
    popupDetailDate: function (isAllDay, start, end) {
      var isSameDate = moment(start).isSame(end);
      var endFormat = (isSameDate ? "" : "YYYY.MM.DD ") + "hh:mm A";

      if (isAllDay) {
        return (
          moment(start).format("YYYY.MM.DD") +
          (isSameDate ? "" : " - " + moment(end).format("YYYY.MM.DD"))
        );
      }

      return (
        moment(start._date).format("LL") +
        " at " +
        moment(start._date).format("LT") +
        " - " +
        moment(end._date).format(endFormat) +
        " " +
        Intl.DateTimeFormat().resolvedOptions().timeZone +
        ""
      );
    },
  };

  function onClickNavi(event) {
    if (event.target.tagName === "BUTTON") {
      const { target } = event;
      let action = target.dataset
        ? target.dataset.action
        : target.getAttribute("data-action");
      action = action.replace("move-", "");
      var calendarInst = cal.current.getInstance();
      calendarInst[action]();
      console.log(action);
      setRenderRangeText();
    }
  }

  function setRenderRangeText() {
    const calDate = cal.current.getInstance().getDate();

    let year = calDate.getFullYear();
    let month = calDate.getMonth();
    let dateRangeText = "";
    dateRangeText = `${monthNames[month]} ${year}`;

    setdateRange(dateRangeText);
  }

  useEffect(() => {
    // Update the document title using the browser API
    if (cal.current) {
      setRenderRangeText();
    }
  });

  return (
    <div>
      <div>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn--plain prev cd-icon feather-icon slick-arrow"
            aria-label="Previous"
            data-action="move-prev"
            onClick={onClickNavi}
          >
            {" "}
            <PrevLogo />
          </button>
          &nbsp;
          {/* <button
            type="button"
            className="btn btn-default btn-sm move-today"
            data-action="move-today"
            onClick={onClickNavi}
          >
            TODAY
          </button> */}
          &nbsp;
          <button
            className="btn--plain next cd-icon feather-icon slick-arrow"
            aria-label="Next"
            data-action="move-next"
            onClick={onClickNavi}
          >
            <NextLogo />
          </button>
        </div>
        <b>{dateRange}</b>
      </div>

      {/* <button
        hidden
        id="eventbrite-widget-modal-trigger-49976790927"
        type="button"
      >
        Register
      </button>
      <button
        hidden
        id="eventbrite-widget-modal-trigger-146801036853"
        type="button"
      >
        Register
      </button>

      <button
        hidden
        id="eventbrite-widget-modal-trigger-147583585477"
        type="button"
      >
        Register
      </button> */}

      <TUICalendar
        ref={cal}
        height="750px"
        view="month"
        useCreationPopup={true}
        useDetailPopup={true}
        template={templates}
        calendars={calendars}
        schedules={schedules}
        onClickSchedule={onClickSchedule}
        onBeforeCreateSchedule={onBeforeCreateSchedule}
        onBeforeDeleteSchedule={onBeforeDeleteSchedule}
        onBeforeUpdateSchedule={onBeforeUpdateSchedule}
        theme={COMMON_CUSTOM_THEME} // set theme
        // disableDblClick={true}
        // disableClick={false}
        isReadOnly={true}
      />
    </div>
  );
}

export default App;
